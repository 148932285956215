import React from "react";

import Layout from "../../../components/layout";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import data from '../../../data/testimonials';

import { Helmet } from 'react-helmet';
export default function About() {

    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data.title + ' | Clean Air Filter'}</title>
        </Helmet>
        <section>
            <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>
            <p dangerouslySetInnerHTML={{ __html: data.content.section1.p1 }}></p>
            <p style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}>
                <Link style={{ marginRight: '1rem' }} className="primary red" to="/contact/submit-your-testimonial">Submit a Testimonial</Link>
                <Link className="primary red" to="/about/testimonials-french">See French</Link>
            </p>
        </section>
        <section>
            <article className="w-60">
                <table className="custom-table">
                    <tbody>
                        {data.content.section3.quotes.map((item, index) => <tr key={index} className="quote-row">
                            <td className="quote-mark-cell"><p><em>"</em></p></td>
                            <td>
                                {item.p.map((subItem, index) => <p key={index}><em>{subItem}</em></p>)}
                                <p className="quote-author-paragraph" dangerouslySetInnerHTML={{ __html: item.author }}></p>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </article>
        </section>
    </Layout>;
}
